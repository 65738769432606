<template>
  <section>
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">CRM </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Leads</b-breadcrumb-item>
            <b-breadcrumb-item active> Dettaglio leads</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <div class="mt-2">
        <b-row>
          <b-col md="2">
            <b-avatar rounded="sm" size="14rem" />

            <div class="my-2 d-flex align-items-center">
              <div class="mr-1">
                <feather-icon
                  icon="PhoneCallIcon"
                  class="text-primary-light"
                  size="25rem"
                />
              </div>

              <div>
                <p class="text-primary-light mb-0 font-weight-bold">
                  Numero di telefono
                </p>
                <p class="mb-0">000-0000000</p>
              </div>
            </div>

            <div class="my-2 d-flex align-items-center">
              <div class="mr-1">
                <feather-icon
                  icon="MailIcon"
                  class="text-primary-light"
                  size="25rem"
                />
              </div>

              <div>
                <p class="text-primary-light mb-0 font-weight-bold">
                  Indirizzo e-mail
                </p>
                <p class="mb-0">000-0000000</p>
              </div>
            </div>

            <div class="my-2 d-flex align-items-center">
              <div class="mr-1">
                <feather-icon
                  icon="CalendarIcon"
                  class="text-primary-light"
                  size="25rem"
                />
              </div>

              <div>
                <p class="text-primary-light mb-0 font-weight-bold">
                  Data inserimento
                </p>
                <p class="mb-0">000-0000000</p>
              </div>
            </div>
          </b-col>

          <b-col md="10">
            <b-tabs>
              <b-tab title="Dati">
                <b-container>
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        class="text-primary-light font-weight-bold"
                        label="Nome"
                      >
                        <b-form-input placeholder="Testo nome"> </b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        class="text-primary-light font-weight-bold"
                        label="Codice"
                      >
                        <b-form-input placeholder="1-n"> </b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        class="text-primary-light font-weight-bold"
                        label="Cognome"
                      >
                        <b-form-input placeholder="Testo cognome">
                        </b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        class="text-primary-light font-weight-bold"
                        label="Partita IVA"
                      >
                        <b-form-input placeholder="Metti qui Partita IVA">
                        </b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        class="text-primary-light font-weight-bold"
                        label="Azienda"
                      >
                        <b-form-input placeholder="Testo azienda">
                        </b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        class="text-primary-light font-weight-bold"
                        label="Codice Fiscale"
                      >
                        <b-form-input placeholder="Metti qui codice fiscale">
                        </b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        class="text-primary-light font-weight-bold"
                        label="Ruolo aziendale"
                      >
                        <b-form-input placeholder="Testo ruolo aziendale">
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-container>
              </b-tab>

              <b-tab title="Timeline">
                <app-timeline>
                  <app-timeline-item
                    title="Goal Achieved"
                    subtitle="All milestones are completed"
                    icon="AwardIcon"
                    time="few minutes ago"
                    variant="primary"
                  />

                  <app-timeline-item
                    title="Last milestone remain"
                    subtitle="You are just one step away from your goal"
                    icon="InfoIcon"
                    time="3 minutes ago"
                    variant="info"
                  />

                  <app-timeline-item
                    title="Your are running low on time"
                    subtitle="Only 30 minutes left to finish milestone"
                    icon="ClockIcon"
                    time="21 minutes ago"
                    variant="warning"
                  />

                  <app-timeline-item
                    title="Client Meeting"
                    subtitle="New event has been added to your schedule"
                    icon="UserIcon"
                    time="36 minutes ago"
                  />

                  <app-timeline-item
                    title="Product Design"
                    subtitle="Product design added in workflow"
                    icon="GridIcon"
                    time="1 hour ago"
                    variant="success"
                  />
                </app-timeline>
              </b-tab>

              <b-tab title="Attività">
                <b-table :fields="fields"> </b-table>
              </b-tab>

              <b-tab title="Note">
                <b-container>
                  <b-form-group
                    class="text-primary-light font-weight-bold"
                    label="Nota aggiuntiva"
                  >
                    <b-form-textarea
                      :rows="3"
                      placeholder="Aggiungi qui la tua nota..."
                    ></b-form-textarea>
                  </b-form-group>

                  <div class="text-right">
                    <b-button variant="primary">Salva nota</b-button>
                  </div>
                </b-container>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </section>
</template>

<script>
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

import {
  BAvatar,
  BButton,
  BContainer,
  BRow,
  BCol,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BTable,
  BTabs,
  BTab,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from "bootstrap-vue";

import { Requests } from "@/api";

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BAvatar,
    BButton,
    BContainer,
    BRow,
    BCol,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BTable,
    BTabs,
    BTab,
    BFormGroup,
    BFormInput,
    BFormTextarea,
  },

  data: () => ({
    fields: [
      {
        key: "creato_da",
      },
      {
        key: "data_attività",
      },
      {
        key: "tipo_attività",
      },
      {
        key: "opzioni",
      },
    ],

    lead: {},
  }),

  created() {
    this.getLeadDetails();
  },

  methods: {
    async getLeadDetails() {
      this.loading = true;

      let request_data = new FormData();

      request_data.append("id", this.$route.params.id);

      try {
        const response = await Requests.getLeadDetails(request_data);
        this.lead = response.crm_database_details.payload;
      } catch (err) {
        console.debug(err);
      }
    },
  },
};
</script>
